import axios, { AxiosResponse } from "axios";
import {
  confirmer_reception_par_demande_url,
  confirmer_reception_par_tournne_annulee_url,
  confirmer_reception_url,
} from "../constants/urls";

import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ValidationData } from "@/interfaces/Return";
import { ServerResponse } from "@/interfaces/Server";
import { Attachement } from "@/interfaces/Attachement";

export async function confirmerReception(
  returnData: ValidationData
): Promise<AxiosResponse<ServerResponse>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse> = await axios({
      url: confirmer_reception_url,
      headers: defaultHeadersWithToken(accessToken),
      data: returnData,
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function confirmerReceptionParDemande(
  returnNumber: string,
  attachement: Attachement
): Promise<AxiosResponse<ServerResponse>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse> = await axios({
      url: confirmer_reception_par_demande_url,
      headers: defaultHeadersWithToken(accessToken),
      data: {
        numTournee: returnNumber,
        attachement: attachement.attachement,
        attachementName: attachement.attachementName,
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}

export async function confirmerReceptionTourneeAnnulee(
  returnNumber: string
): Promise<AxiosResponse<ServerResponse>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse> = await axios({
      url: confirmer_reception_par_tournne_annulee_url,
      headers: defaultHeadersWithToken(accessToken),
      data: {
        numTournee: parseInt(returnNumber),
      },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
