import axios, { AxiosResponse } from "axios";
import { annuler_tournee_url } from "../constants/urls";
import { store } from "../store/store";
import { defaultHeadersWithToken } from "../constants/constants";
import { ServerResponse_ } from "@/interfaces/Server";

export async function annulerTournee(
  numTournee: number
): Promise<AxiosResponse<ServerResponse_<string>>> {
  const accessToken = store.getState().authentication.accessToken;
  try {
    const response: AxiosResponse<ServerResponse_<string>> = await axios({
      url: annuler_tournee_url,
      headers: defaultHeadersWithToken(accessToken),
      data: { numTournee: numTournee },
      method: "POST",
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
}
