import React, { useEffect } from "react";

import { LogOut, Plus, Search } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { PRIMARY_COLOR, WAREHOUSE_ROLE } from "@/constants/constants";
import { Input } from "../ui/input";
import logo from "/src/assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { setSearchText } from "@/store/reducers/searchReducer";
import { Button } from "../ui/button";
import { RootState } from "@/store/store";
import { useNavigate } from "react-router-dom";
import { openDialog } from "@/store/reducers/dialogReducer";
import {
  LOGOUT_CONFIRMATION_DIALOG,
  RETURN_CREATION_DIALOG,
} from "@/constants/dialog-constants";
import { truncateText } from "@/utils/text-utills";
import { NavigationRoutes } from "../navigation/constants";

interface HeaderProps {
  title?: string;
}

const Header: React.FC<HeaderProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.authentication);
  const [searchText_, setSearchText_] = React.useState("");
  useEffect(() => {
    dispatch(setSearchText(searchText_));
  }, [searchText_]);

  const handleLogoClick = () => {
    navigate(NavigationRoutes.DASHBOARD);
  };

  const handleLogout = () => {
    dispatch(openDialog(LOGOUT_CONFIRMATION_DIALOG));
  };

  const handleOpenCreateDialog = () => {
    dispatch(openDialog(RETURN_CREATION_DIALOG));
  };

  return (
    <header className="shadow-md">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="hidden md:flex items-center">
            <img
              src={logo}
              alt="Logo"
              className="h-8 w-auto hover:cursor-pointer"
              onClick={handleLogoClick}
            />
          </div>
          <div className="flex flex-1 max-w-xl mx-4 flex-row gap-2">
            <div className="relative flex-grow">
              <Input
                type="search"
                placeholder="Search..."
                className="w-full pl-10 pr-4 h-10"
                value={searchText_}
                onChange={(e) => setSearchText_(e.target.value)}
              />
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400"
                strokeWidth={2}
              />
            </div>
            {user.roles.includes(WAREHOUSE_ROLE) && (
              <Button
                size="icon"
                className="h-10 w-10 flex-shrink-0"
                onClick={handleOpenCreateDialog}
              >
                <Plus className="h-4 w-4" />
                <span className="sr-only">Add new item</span>
              </Button>
            )}
          </div>
          <div className="flex items-center gap-2">
            <p
              className="text-sm font-semibold hidden md:block"
              style={{ color: PRIMARY_COLOR }}
            >
              {truncateText(user.email, 20)}
            </p>
            <Separator
              orientation="vertical"
              className="h-6 w-0.5 mx-2 hidden md:block"
              style={{ backgroundColor: PRIMARY_COLOR }}
            />
            <Button
              className="p-3 bg-white hover:bg-gray-100"
              onClick={handleLogout}
            >
              <LogOut size={20} style={{ color: PRIMARY_COLOR }} />
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
