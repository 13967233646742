import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

import { RootState } from "@/store/store";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { Button } from "../ui/button";
import DialogLoaderButton from "../loader-button/DialogLoaderButton";
import { DELIVERY_CONFIRMATION_BY_PJ_DIALOG } from "@/constants/constants";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../navigation/constants";

interface ReturnConfirmationByPJDialogProps {
  handleValidate_: () => void;
}

const ReturnConfirmationByPJDialog: React.FC<
  ReturnConfirmationByPJDialogProps
> = ({ handleValidate_ }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialog
  );
  const isOpened = isOpen && dialogType === DELIVERY_CONFIRMATION_BY_PJ_DIALOG;

  const handleClose = () => {
    dispatch(closeDialog());
  };
  const handleRedirectToLitigePage = () => {
    navigate(NavigationRoutes.REQUEST_LITIGE);
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogDescription>
            <span> Étes-vous sûr de vouloir confirmer la livraison ?</span>
            <br />
            <span>
              {" "}
              Si vous avez besoin de decliner un litige, veuillez cliquer sur le
              bouton ci-dessous.
            </span>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex flex-col sm:flex-row sm:justify-between gap-2">
          <div className="flex gap-2">
            <Button
              variant="outline"
              onClick={handleRedirectToLitigePage}
              className="border-red-500 text-red-500 hover:bg-red-50 hover:text-red-600 w-full"
            >
              Déclarer un litige
            </Button>
          </div>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-2">
            <Button variant="outline" onClick={handleClose}>
              Annuler
            </Button>
            <DialogLoaderButton
              name="Confirmer"
              handleSubmit={handleValidate_}
              isLoading={false}
            />
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ReturnConfirmationByPJDialog;
