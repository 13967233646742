import CancelReturnDialog from "@/components/dialogs/CancelReturnDialog";
import ReturnConfirmationByPJDialog from "@/components/dialogs/ReturnConfirmationByPJDialog";
import ActionButton from "@/components/loader-button/ActionButton";
import { NavigationRoutes } from "@/components/navigation/constants";
import { Button } from "@/components/ui/button";
import { Toaster } from "@/components/ui/toaster";
import {
  DELIVERY_CONFIRMATION_BY_PJ_DIALOG,
  DELIVERY_ROLE,
  HUB_ROLE,
  SELLER_ROLE,
} from "@/constants/constants";
import { toast } from "@/hooks/use-toast";
import { validerRetourVendeurParLivreur } from "@/services/delivery-return-validation.service";
import { validerRetourVendeurParHUB } from "@/services/hub-return-validation.service";
import { confirmerReceptionParDemande } from "@/services/return-reception-confirmation.service";
import { closeDialog, openDialog } from "@/store/reducers/dialogReducer";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { RootState } from "@/store/store";
import { convertFileToAttachement } from "@/utils/file-utils";
import { Camera, X } from "lucide-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReturnValidationByPJPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const return_ = useSelector((state: RootState) => state.return.return);
  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  const [proofFile, setProofFile] = React.useState<File>({} as File);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);
  const roles = useSelector((state: RootState) => state.authentication.roles);
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialog
  );
  const isOpened = isOpen && dialogType === DELIVERY_CONFIRMATION_BY_PJ_DIALOG;

  const handleValiteReturnByPJ = async () => {
    if (!isOpened) {
      dispatch(openDialog(DELIVERY_CONFIRMATION_BY_PJ_DIALOG));
      return;
    }
    setIsLoading(true);
    dispatch(closeDialog());
    const image = await convertFileToAttachement(proofFile);
    let response;
    try {
      if (roles.includes(DELIVERY_ROLE)) {
        response = await validerRetourVendeurParLivreur(
          return_.numTournee.toString(),
          image
        );
      } else if (roles.includes(HUB_ROLE)) {
        response = await validerRetourVendeurParHUB(
          return_.numTournee.toString(),
          image
        );
      } else if (roles.includes(SELLER_ROLE)) {
        response = await confirmerReceptionParDemande(
          return_.numTournee.toString(),
          image
        );
      } else {
        throw new Error("Invalid role");
      }

      if (response.status === 200 && response.data.success) {
        toast({
          title: "Retour validé",
          variant: "success",
        });
        navigate(NavigationRoutes.DASHBOARD, { replace: true });
        dispatch(setRefreshing(!isRefreshing));
      } else {
        throw new Error("Validation failed");
      }
    } catch (error) {
      toast({
        title: "Erreur",
        description: "Erreur lors de la validation du retour",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
      setProofFile({} as File);
    }
  };

  const handleCheckDisabled = () => {
    return proofFile.name === undefined;
  };
  const handlePJClick = () => {
    if (isLoading) return;
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    const allowedTypes = [".png", ".jpg", ".jpeg"];
    input.accept = allowedTypes.join(",");

    input.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      if (target.files && target.files.length > 0) {
        const file = target.files[0];
        const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`;

        if (!allowedTypes.includes(fileExtension)) {
          toast({
            title: "Type de fichier non supporté",
            description: `Fichiers autorisés sont: ${allowedTypes.join(", ")}`,
            variant: "destructive",
          });
          return;
        }

        setProofFile(file);
        toast({
          title: "Fichier ajouté",
          variant: "default",
        });
      }
    };
    input.click();
  };

  const handleOpenCancelDialog = () => {
    setIsCancelDialogOpen(!isCancelDialogOpen);
  };

  useEffect(() => {
    if (return_.numTournee === null || return_.numTournee === undefined) {
      navigate(NavigationRoutes.DASHBOARD);
    }
  }, []);

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="px-4  flex items-start justify-between gap-2 flex-row ">
        <p className="text-lg font-semibold">
          N° de retour: {return_.numTournee}
        </p>
        <p className="text-lg font-semibold">Vendeur: {return_.sellerName}</p>
      </div>
      {proofFile.name ? (
        <div className="relative w-full  rounded-md overflow-hidden group">
          <Button
            className="absolute top-1 right-1 h-6 w-6 p-0 bg-red-500 hover:bg-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
            onClick={() => setProofFile({} as File)}
            disabled={isLoading}
          >
            <X className="w-4 h-4" />
          </Button>
          <img
            src={URL.createObjectURL(proofFile)}
            alt="Proof"
            className="w-full h-full object-contain rounded-md hover:cursor-pointer border border-primary"
            onClick={handlePJClick}
          />
        </div>
      ) : (
        <div
          className="rounded-md w-full h-full bg-slate-200 hover:bg-slate-300 items-center justify-center flex flex-col gap-4 hover:cursor-pointer"
          onClick={() => {
            handlePJClick();
          }}
        >
          <Camera size={32} className="text-gray-500" />
          <p className="text-sm font-semibold text-gray-500">
            Prenez une photo de la preuve
          </p>
        </div>
      )}
      <div className=" flex space-x-4 flex-row">
        {(roles.includes(SELLER_ROLE) ||
          roles.includes(HUB_ROLE) ||
          roles.includes(DELIVERY_ROLE)) && (
          <ActionButton
            loading={isLoading}
            disabled={false}
            handleClick={handleOpenCancelDialog}
            color={"bg-red-500 hover:bg-red-600"}
            label="Refuser"
          />
        )}
        <ActionButton
          handleClick={handleValiteReturnByPJ}
          loading={isLoading}
          disabled={handleCheckDisabled()}
          label="Valider le retour"
        />
      </div>
      <Toaster />
      <ReturnConfirmationByPJDialog handleValidate_={handleValiteReturnByPJ} />
      <CancelReturnDialog
        isOpened={isCancelDialogOpen}
        setIsOpened={setIsCancelDialogOpen}
        returnNumber={return_.numTournee}
      />
    </div>
  );
};

export default ReturnValidationByPJPage;
