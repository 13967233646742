import React, { useEffect } from "react";
import { Button } from "../ui/button";
import { LoaderCircle } from "lucide-react";

interface LoaderButtonProps {
  isLoading: boolean;
  handleSubmit: () => void;
  name: string;
  disabled?: boolean;
  outline?: boolean;
  icon?: React.ReactNode;
  variant?: "default" | "destructive" | "outline";
}

const DialogLoaderButton: React.FC<LoaderButtonProps> = ({
  isLoading,
  name,
  handleSubmit,
  disabled,
  outline,
  icon,
  variant,
}) => {
  useEffect(() => {}, [isLoading, disabled]);
  return (
    <>
      {isLoading ? (
        <Button
          disabled
          variant={outline ? "outline" : "default"}
          className={`${
            variant === "destructive" ? "bg-red-500 hover:bg-red-600" : ""
          }`}
        >
          <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
          {name}
        </Button>
      ) : (
        <Button
          type="submit"
          onClick={handleSubmit}
          disabled={disabled}
          variant={outline ? "outline" : "default"}
          className={`${
            variant === "destructive" ? "bg-red-500 hover:bg-red-600" : ""
          }`}
        >
          {icon && icon}
          {name}
        </Button>
      )}
    </>
  );
};

export default DialogLoaderButton;
