import React from "react";

interface StatusTagProps {
  text: string;
  bakcgroundColor: string;
}

const StatusTag: React.FC<StatusTagProps> = ({ text, bakcgroundColor }) => {
  return (
    <div
      className="inline-flex items-center rounded-md px-2 py-1"
      style={{ backgroundColor: bakcgroundColor }}
    >
      <p className="text-xs font-medium text-white">{text}</p>
    </div>
  );
};

export default StatusTag;
