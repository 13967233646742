import { SECONDARY_COLOR } from "@/constants/constants";
import React from "react";

interface ProductCounterTagProps {
  productCount: number;
}

const ProductCounter: React.FC<ProductCounterTagProps> = ({ productCount }) => {
  return (
    <div
      className="inline-flex items-center space-x-1 border rounded-md px-2 py-1"
      style={{ borderColor: SECONDARY_COLOR }}
    >
      <span
        className="text-xs font-semibold"
        style={{ color: SECONDARY_COLOR }}
      >
        Produits:
      </span>
      <span className="text-xs font-bold" style={{ color: SECONDARY_COLOR }}>
        {productCount}
      </span>
    </div>
  );
};

export default ProductCounter;
