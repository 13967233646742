import React, { useEffect } from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { LoaderCircle } from "lucide-react";
import { getReturnNumber } from "@/services/return-number-generation.service";

interface ReturnCodeInputProps {
  setReturnCode_: (returnCode: number) => void;
}

const ReturnCodeInput: React.FC<ReturnCodeInputProps> = ({
  setReturnCode_,
}) => {
  const [returnCode, setReturnCode] = React.useState<number>(0);
  const [returnCodeLoading, setReturnCodeLoading] = React.useState(false);
  const generateReturnCode = async () => {
    setReturnCodeLoading(true);
    const response = await getReturnNumber();
    if (response.status === 200 && response.data.success === true) {
      setReturnCode(response.data.data.num_tournee);
      setReturnCode_(response.data.data.num_tournee);
      setReturnCodeLoading(false);
    } else {
      setReturnCodeLoading(false);
    }
    setReturnCodeLoading(false);
  };

  useEffect(() => {
    generateReturnCode();
  }, []);

  return (
    <div className="space-y-1">
      <Label>Numero Tournée:</Label>
      <div className="flex items-center space-x-2">
        <Input value={returnCode} disabled className="w-full" />
        {returnCodeLoading && (
          <LoaderCircle className="animate-spin" color="grey" size={20} />
        )}
      </div>
    </div>
  );
};

export default ReturnCodeInput;
