import React from "react";

interface SourceTagProps {
  text: string | undefined;
}

const SourceTag: React.FC<SourceTagProps> = ({ text }) => {
  return (
    <>
      {text && (
        <div className="inline-flex items-center rounded-md px-2 py-1 bg-[#edbe95]">
          <p className="text-xs font-medium text-white">{text}</p>
        </div>
      )}
    </>
  );
};

export default SourceTag;
