import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Button } from "../ui/button";
import DialogLoaderButton from "../loader-button/DialogLoaderButton";
import { confirmerReceptionTourneeAnnulee } from "@/services/return-reception-confirmation.service";
import { toast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../navigation/constants";

interface CanceledReturnConfirmationDialogProps {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  returnNumber: string;
}

const CanceledReturnConfirmationDialog: React.FC<
  CanceledReturnConfirmationDialogProps
> = ({ isOpened, setIsOpened, returnNumber }) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleValidate_ = async () => {
    try {
      setIsLoading(true);
      const response = await confirmerReceptionTourneeAnnulee(returnNumber);
      if (response.status === 200 && response.data.success === true) {
        toast({
          title: "Réception confirmée",
          variant: "success",
        });
      }
      setIsOpened(false);
      navigate(NavigationRoutes.DASHBOARD, { state: { tab: "cancelled" } });
    } catch (error) {
      console.error(error);
      toast({
        title: "Erreur lors de la confirmation",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpened(false);
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogDescription>
            Étes-vous sûr de vouloir confirmer la réception ?
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:gap-0">
          <Button
            variant="outline"
            className="mr-2"
            onClick={handleClose}
            disabled={isLoading}
          >
            Annuler
          </Button>
          <DialogLoaderButton
            name="Confirmer"
            handleSubmit={handleValidate_}
            isLoading={isLoading}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CanceledReturnConfirmationDialog;
