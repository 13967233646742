import { RequestLitigeArticle } from "@/interfaces/Product";
import { Button } from "../ui/button";
import { Plus, Minus, FilePlus, X } from "lucide-react";
import { useEffect, useState } from "react";
import { toast } from "@/hooks/use-toast";
import { Motif } from "@/interfaces/Motif";
import MotifSelect from "../motif-select/MotifSelect";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import { convertFilesToAttachments } from "@/utils/file-utils";

interface RequestAdjustableProductProps {
  product: RequestLitigeArticle;
  setProducts: React.Dispatch<React.SetStateAction<RequestLitigeArticle[]>>;
  disabled?: boolean;
  initialQuantity: number;
  motifs: Motif[];
}
const RequestAdjustableProduct: React.FC<RequestAdjustableProductProps> = ({
  product,
  setProducts,
  disabled,
  motifs,
  initialQuantity,
}) => {
  const [motif, setMotif] = useState<Motif>({} as Motif);
  const [files, setFiles] = useState<File[]>([]);

  const handleIncrease = () => {
    if (product.quantity && product.quantity + 1 > initialQuantity) {
      toast({
        title: "Quantité maximale atteinte",
        variant: "destructive",
      });
      return;
    }
    setProducts((prev) =>
      prev.map((p) =>
        p.product.genCode === product.product.genCode
          ? { ...p, quantity: (p.quantity ?? 0) + 1 }
          : p
      )
    );
  };

  const handleDecrease = () => {
    if (product.quantity && product.quantity > 0) {
      setProducts((prev) =>
        prev.map((p) =>
          p.product.genCode === product.product.genCode
            ? { ...p, quantity: (p.quantity ?? 0) - 1 }
            : p
        )
      );
    }
  };

  const handleFileChange = (file: File) => {
    const selectedFile = file;
    if (selectedFile) {
      if (files?.length < 3) {
        setFiles?.([...(files ?? []), selectedFile]);
        toast({
          title: "Fichier ajouté",
          description: `Le fichier ${selectedFile.name} a été ajouté`,
          variant: "success",
        });
      } else {
        toast({
          title: "Limite de fichiers atteinte",
          description: "Vous ne pouvez pas ajouter plus de 3 fichiers",
          variant: "default",
        });
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    toast({
      title: "Fichier retiré",
      description: "Le fichier a été retiré avec succès",
    });
  };

  useEffect(() => {
    setProducts((prev) =>
      prev.map((p) =>
        p.product.genCode === product.product.genCode
          ? { ...p, motifId: motif.id }
          : p
      )
    );
  }, [motif]);

  useEffect(() => {
    const handleFileChange = async () => {
      const atts = await convertFilesToAttachments(files);
      setProducts((prev) =>
        prev.map((p) =>
          p.product.genCode === product.product.genCode
            ? { ...p, attachements: atts }
            : p
        )
      );
    };
    handleFileChange();
  }, [files]);

  return (
    <div className="w-full flex flex-col sm:flex-row rounded-lg shadow-md bg-white my-2 border border-primary p-3 gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center w-full gap-4 sm:gap-2">
          <div className="flex items-center space-x-4 justify-start align-middle">
            <img
              src={product.product.image}
              alt="product"
              className="w-16 h-16 sm:w-20 sm:h-20"
            />
            <div className="flex justify-between w-full flex-col">
              <p className="text-base font-bold text-gray-700">
                {product.product.genCode}
              </p>
              <p className="text-sm text-gray-500 font-semibold">
                {product.product.articleName}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-start sm:justify-center items-center mt-2 sm:mt-0">
            <Button
              variant="outline"
              onClick={handleDecrease}
              disabled={disabled}
            >
              <Minus className="h-4 w-4" />
            </Button>
            <p className="text-sm text-gray-500 font-semibold text-right mx-4">
              {product.quantity}
            </p>
            <Button
              variant="outline"
              onClick={handleIncrease}
              disabled={disabled || initialQuantity === 0}
            >
              <Plus className="h-4 w-4" />
            </Button>
            <div className="relative px-2">
              <Button
                variant="outline"
                size="icon"
                disabled={files.length >= 3 || disabled}
                onClick={() => {
                  const input = document.createElement("input");
                  input.type = "file";
                  input.multiple = true;
                  input.accept = [".png", ".jpg", ".jpeg", ".pdf"].join(",");
                  input.onchange = (e) => {
                    const target = e.target as HTMLInputElement;
                    if (target.files && target.files.length > 0) {
                      handleFileChange(target.files[0]);
                    }
                  };
                  input.click();
                }}
              >
                <FilePlus className="w-4 h-4" />
              </Button>
              {files.length > 0 && (
                <span className="absolute -top-2 -right-2 bg-primary text-primary-foreground text-xs font-bold rounded-full w-5 h-5 flex items-center justify-center">
                  {files.length}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 w-full sm:w-auto mt-4 sm:mt-0">
          <MotifSelect
            selectedMotif={motif}
            setSelectedMotif={setMotif}
            disabled={disabled}
            motifs={motifs}
            noLabel
          />
        </div>
        {files.length > 0 && (
          <div className="flex flex-col gap-2">
            <ScrollArea className="w-full whitespace-nowrap rounded-md h-28 mt-4 sm:mt-2">
              <div className="flex gap-4">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="relative w-28 h-28 bg-gray-200 rounded-md overflow-hidden group"
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`file ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                    <Button
                      disabled={disabled}
                      className="absolute top-1 right-1 h-6 w-6 p-0 bg-red-500 hover:bg-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                      onClick={() => handleRemoveFile(index)}
                      aria-label={`Remove file ${index + 1}`}
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  </div>
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestAdjustableProduct;
