import LitigeConfirmationDialog from "@/components/dialogs/LitigeConfirmationDialog";
import ActionButton from "@/components/loader-button/ActionButton";
import { NavigationRoutes } from "@/components/navigation/constants";
import RequestAdjustableProduct from "@/components/product/RequestAdjustableProduct";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Toaster } from "@/components/ui/toaster";
import { LITIGE_CONFIRMATION_DIALOG } from "@/constants/constants";
import { toast } from "@/hooks/use-toast";
import { RequestLitige } from "@/interfaces/Litige";
import { Motif } from "@/interfaces/Motif";
import { RequestLitigeArticle } from "@/interfaces/Product";
import { createRequestLitige } from "@/services/litige-creation.service";
import { getMotifs_ } from "@/services/motif-list.service";
import { closeDialog, openDialog } from "@/store/reducers/dialogReducer";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { RootState } from "@/store/store";
import { LoaderCircle } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReturnRequestLitigePage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialog
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  const isOpened = isOpen && dialogType === LITIGE_CONFIRMATION_DIALOG;
  const [validProducts, setValidProducts] = useState<RequestLitigeArticle[]>(
    [] as RequestLitigeArticle[]
  );
  const [isLoading, setIsLoading] = useState(false);
  const return_ = useSelector((state: RootState) => state.return.return);
  const [motifLoading, setMotifLoading] = useState(false);
  const [motifs, setMotifs] = useState<Motif[]>([]);

  const fetchMotifs = async () => {
    try {
      setMotifLoading(true);
      const response = await getMotifs_();
      if (response.data.success === true) {
        setMotifs(response.data.data);
        setMotifLoading(false);
      }
    } catch (error) {
      console.error(error);
      setMotifLoading(false);
    } finally {
      setMotifLoading(false);
    }
  };

  const handleContinue = async () => {
    if (!isOpened) {
      dispatch(openDialog(LITIGE_CONFIRMATION_DIALOG));
      return;
    } else {
      setIsLoading(true);
      dispatch(closeDialog());
      const payload: RequestLitige = {
        numTournee: return_.numTournee.toString(),
        commentaire: "",
        articles: validProducts.map((product) => {
          return {
            productId: product.product.productId,
            motifId: product.motifId,
            quantity: product.quantity,
            attachements: product.attachements,
          };
        }),
      };
      const success = await createRequestLitige(payload);
      if (success) {
        setIsLoading(false);
        navigate(NavigationRoutes.DASHBOARD, { replace: true });
        dispatch(setRefreshing(!isRefreshing));
        toast({
          title: "Litige créé avec succès",
          variant: "success",
        });
        setIsLoading(false);
      } else {
        toast({
          title: "Erreur lors de la création du litige",
          variant: "destructive",
        });
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  const handleContinueButtonDisabled = () => {
    const validProducts_ = validProducts.filter(
      (product) => (product.product?.quantity ?? 0) > 0
    );
    const hasValidProduct = validProducts_.some((product) => {
      const hasQuantity = product.quantity > 0;
      const hasMotif = product.motifId !== undefined && product.motifId !== "";
      return hasQuantity && hasMotif;
    });

    return !hasValidProduct;
  };

  useEffect(() => {
    fetchMotifs();
  }, []);

  useEffect(() => {
    const arts = return_.articles.map((article) => {
      return {
        product: article,
        quantity: 0,
        motifId: "",
        attachements: [],
      };
    });
    setValidProducts(arts);
  }, []);

  useEffect(() => {}, [validProducts]);

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="px-4  flex items-start justify-between gap-2 flex-row py-2">
        <p className="text-lg font-semibold">
          N° de retour: {return_.numTournee}
        </p>
        <p className="text-lg font-semibold">Vendeur: {return_.sellerName}</p>
      </div>

      {!motifLoading ? (
        <ScrollArea className="flex flex-grow px-2 py-2">
          {validProducts.length > 0 ? (
            validProducts.map((article) => (
              <div className="px-4">
                <RequestAdjustableProduct
                  product={article}
                  initialQuantity={
                    return_.articles.find(
                      (a) => a.genCode === article.product.genCode
                    )?.quantity
                  }
                  setProducts={setValidProducts}
                  disabled={isLoading}
                  motifs={motifs}
                />
              </div>
            ))
          ) : (
            <p>Aucun produit dans le retour</p>
          )}
        </ScrollArea>
      ) : (
        <div className="flex flex-grow px-2 items-center justify-center">
          <LoaderCircle className="h-8 w-8 animate-spin" />
        </div>
      )}

      <div className="px-4">
        <ActionButton
          loading={isLoading}
          disabled={isLoading || handleContinueButtonDisabled()}
          handleClick={handleContinue}
          label="Valider le litige"
        />
      </div>

      <Toaster />
      <LitigeConfirmationDialog handleValidate_={handleContinue} />
    </div>
  );
};

export default ReturnRequestLitigePage;
