import CancelReturnDialog from "@/components/dialogs/CancelReturnDialog";
import DeliveryConfirmationDialog from "@/components/dialogs/DeliveryConfirmationDialog";
import ActionButton from "@/components/loader-button/ActionButton";
import { NavigationRoutes } from "@/components/navigation/constants";
import ProductScanner from "@/components/product-scan/ProductScanner";
import { Toaster } from "@/components/ui/toaster";
import {
  DELIVERY_CONFIRMATION_DIALOG,
  DELIVERY_ROLE,
  HUB_ROLE,
  SELLER_ROLE,
} from "@/constants/constants";
import { toast } from "@/hooks/use-toast";
import { Article } from "@/interfaces/Product";
import { ReturnToCreate } from "@/interfaces/Return";
import { confirmerReceptionHUB } from "@/services/hub-reception-confirmation.service";
import { confirmerReception } from "@/services/return-reception-confirmation.service";
import { closeDialog, openDialog } from "@/store/reducers/dialogReducer";
import { RootState } from "@/store/store";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReturnDeliveryValidationPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validProducts, setValidProducts] = React.useState<Article[]>([]);
  const [isLoading, setIsloading] = React.useState(false);
  const return_ = useSelector((state: RootState) => state.return.return);
  const isOpen = useSelector((state: RootState) => state.dialog.isOpen);
  const roles = useSelector((state: RootState) => state.authentication.roles);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = React.useState(false);

  const validationButtonDisabled = () => {
    return validProducts.length === 0;
  };

  const handleValidation = async () => {
    if (isOpen === false) {
      dispatch(openDialog(DELIVERY_CONFIRMATION_DIALOG));
      return;
    }
    const request = {
      numTournee: return_.numTournee.toString() || "",
      articles: validProducts,
      isSellerVIP: return_.isSellerVIP,
      sellerId: return_.sellerId,
      entityId: return_.entityId,
    } as ReturnToCreate;
    dispatch(closeDialog());
    setIsloading(true);
    if (roles.includes(HUB_ROLE)) {
      const response = await confirmerReceptionHUB(request);
      if (response.status === 200 && response.data.success === true) {
        toast({
          title: "Réception validée",
          variant: "success",
        });
        navigate(NavigationRoutes.DASHBOARD, { replace: true });
      } else {
        toast({
          title: "Erreur lors de la validation",
          variant: "destructive",
        });
      }
    } else {
      const response = await confirmerReception(request);
      if (response.status === 200 && response.data.success === true) {
        toast({
          title: "Réception validée",
          variant: "success",
        });
        navigate(NavigationRoutes.DASHBOARD, { replace: true });
      } else {
        toast({
          title: "Erreur lors de la validation",
          variant: "destructive",
        });
      }
      setIsloading(false);
    }
  };

  const handleOpenCancelDialog = () => {
    setIsCancelDialogOpen(!isCancelDialogOpen);
  };

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="px-4 py-4 flex items-start justify-between gap-2 flex-row ">
        <p className="text-lg font-semibold">
          N° de retour: {return_.numTournee}
        </p>
        <p className="text-lg font-semibold">Vendeur: {return_.sellerName}</p>
      </div>
      <ProductScanner
        validProducts={validProducts}
        setValidProducts={setValidProducts}
        disabled={isLoading}
      />
      <div className="px-4 flex space-x-4 flex-row">
        {(roles.includes(SELLER_ROLE) ||
          roles.includes(HUB_ROLE) ||
          roles.includes(DELIVERY_ROLE)) && (
          <ActionButton
            loading={isLoading}
            disabled={false}
            handleClick={handleOpenCancelDialog}
            color={"bg-red-500 hover:bg-red-600"}
            label="Refuser"
          />
        )}
        <ActionButton
          loading={isLoading}
          disabled={validationButtonDisabled() || isLoading}
          handleClick={handleValidation}
          label="Valider le réception"
        />
      </div>
      <Toaster />
      <DeliveryConfirmationDialog handleValidate_={handleValidation} />
      <CancelReturnDialog
        isOpened={isCancelDialogOpen}
        setIsOpened={setIsCancelDialogOpen}
        returnNumber={return_.numTournee}
      />
    </div>
  );
};

export default ReturnDeliveryValidationPage;
