import LitigeConfirmationDialog from "@/components/dialogs/LitigeConfirmationDialog";
import ActionButton from "@/components/loader-button/ActionButton";
import MotifSelect from "@/components/motif-select/MotifSelect";
import { NavigationRoutes } from "@/components/navigation/constants";
import AdjustableProductScanner from "@/components/product-scan/AdjustableProductScanner";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Toaster } from "@/components/ui/toaster";
import { LITIGE_CONFIRMATION_DIALOG } from "@/constants/constants";
import { toast } from "@/hooks/use-toast";
import { Litige } from "@/interfaces/Litige";
import { Motif } from "@/interfaces/Motif";
import { Article } from "@/interfaces/Product";
import { createLitige } from "@/services/litige-creation.service";
import { getMotifs } from "@/services/motif-list.service";
import { closeDialog, openDialog } from "@/store/reducers/dialogReducer";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { RootState } from "@/store/store";
import { convertFilesToAttachments } from "@/utils/file-utils";
import { Upload, X } from "lucide-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ReturnLitigePage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState<File[]>([] as File[]);
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialog
  );

  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  const isOpened = isOpen && dialogType === LITIGE_CONFIRMATION_DIALOG;
  const [validProducts, setValidProducts] = React.useState<Article[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const return_ = useSelector((state: RootState) => state.return.return);
  const [selectedMotif, setSelectedMotif] = React.useState<Motif>({} as Motif);
  const [motifLoading, setMotifLoading] = React.useState(false);
  const [motifs, setMotifs] = React.useState<Motif[]>([]);
  const [comment, setComment] = React.useState("");

  const fetchMotifs = async () => {
    setMotifLoading(true);
    const response = await getMotifs();
    if (response.data.success === true) {
      setMotifs(response.data.data);
      setMotifLoading(false);
    }
    setMotifLoading(false);
  };

  const handleRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    toast({
      title: "File removed",
      description: "The selected file has been removed",
    });
  };

  const handleFileChange = (file: File) => {
    const selectedFile = file;
    if (selectedFile) {
      setFiles([...files, selectedFile]);
      toast({
        title: "File selected",
        description: `You've selected ${selectedFile.name}`,
      });
    }
  };

  const handleDisabledCheck = () => {
    if (selectedMotif.id && validProducts.length > 0 && files.length > 0) {
      return false;
    }
    return true;
  };

  const handleContinue = async () => {
    if (!isOpened) {
      dispatch(openDialog(LITIGE_CONFIRMATION_DIALOG));
      return;
    } else {
      const attachements = await convertFilesToAttachments(files);
      const litige: Litige = {
        numTournee: return_.numTournee.toString() || "",
        motifId: parseInt(selectedMotif.id),
        articles: validProducts,
        commentaire: comment,
        attachements: attachements,
      };

      setIsLoading(true);
      dispatch(closeDialog());
      const success = await createLitige(litige);
      if (success) {
        setIsLoading(false);
        navigate(NavigationRoutes.DASHBOARD, { replace: true });
        dispatch(setRefreshing(!isRefreshing));
        toast({
          title: "Litige créé avec succès",
          variant: "success",
        });
        setIsLoading(false);
      } else {
        toast({
          title: "Erreur lors de la création du litige",
          variant: "destructive",
        });
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchMotifs();
  }, []);

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="px-4  flex items-start justify-between gap-2 flex-row ">
        <p className="text-lg font-semibold">
          N° de retour: {return_.numTournee}
        </p>
        <p className="text-lg font-semibold">Vendeur: {return_.sellerName}</p>
      </div>

      <AdjustableProductScanner
        validProducts={validProducts}
        setValidProducts={setValidProducts}
        fixed={true}
        forLitige={true}
        disabled={isLoading}
      />

      <div className="px-4">
        <MotifSelect
          selectedMotif={selectedMotif}
          setSelectedMotif={setSelectedMotif}
          disabled={isLoading}
          motifs={motifs}
          loading={motifLoading}
        />
      </div>
      <div className="px-4">
        <Input
          className="w-full h-10"
          placeholder="Commentaire"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          disabled={isLoading}
        />
      </div>
      <div className="flex px-4 flex-row gap-4">
        <Button
          disabled={isLoading}
          className="h-28 w-32 items-center justify-center"
          onClick={() => {
            const input = document.createElement("input");
            input.type = "file";
            input.multiple = true;
            input.accept = [".png", ".jpg", ".jpeg", ".pdf"].join(",");
            input.onchange = (e) => {
              const target = e.target as HTMLInputElement;
              if (target.files && target.files.length > 0) {
                handleFileChange(target.files[0]);
              }
            };
            input.click();
          }}
        >
          <Upload className="w-4 h-4" />
        </Button>
        <ScrollArea className="w-full whitespace-nowrap rounded-md h-28">
          <div className="flex gap-4">
            {files.map((file, index) => (
              <div
                key={index}
                className="relative w-28 h-28 bg-gray-200 rounded-md overflow-hidden group"
              >
                <img
                  src={URL.createObjectURL(file)}
                  alt={`file ${index + 1}`}
                  className="w-full h-full object-cover"
                />
                <Button
                  disabled={isLoading}
                  className="absolute top-1 right-1 h-6 w-6 p-0 bg-red-500 hover:bg-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                  onClick={() => handleRemoveFile(index)}
                  aria-label={`Remove file ${index + 1}`}
                >
                  <X className="w-4 h-4" />
                </Button>
              </div>
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>

      <div className="px-4">
        <ActionButton
          loading={isLoading}
          disabled={isLoading || handleDisabledCheck()}
          handleClick={handleContinue}
          label="Valider le litige"
        />
      </div>

      <Toaster />
      <LitigeConfirmationDialog handleValidate_={handleContinue} />
    </div>
  );
};

export default ReturnLitigePage;
