import { ReturnRequest } from "@/interfaces/Return";
import React from "react";
import { Button } from "../ui/button";
import { truncateText } from "@/utils/text-utills";
import { X } from "lucide-react";

interface RequestListProps {
  selectedRequests: ReturnRequest[];
  handleShowDetails: (request: ReturnRequest) => void;
  handleRemoveRequest: (request: ReturnRequest) => void;
  disabled?: boolean;
}

const RequestList: React.FC<RequestListProps> = ({
  selectedRequests,
  handleRemoveRequest,
  handleShowDetails,
  disabled,
}) => {
  return (
    <>
      {selectedRequests.length > 0 && (
        <div className="space-y-2">
          {selectedRequests.map((request) => (
            <div key={request.num} className="flex items-center gap-2">
              <Button
                disabled={disabled}
                variant="outline"
                className="w-full items-left"
                onClick={() => handleShowDetails(request)}
              >
                {truncateText("Demande N°" + request.num.toString(), 20)}
              </Button>
              <Button
                variant="outline"
                className="hover:bg-white border-none"
                onClick={() => handleRemoveRequest(request)}
              >
                <X className="w-4 h-4" />
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default RequestList;
