import { IReturn } from "@/interfaces/Return";
import {
  DELIVERY_ROLE,
  HUB_ROLE,
  MILKRUN_ID,
  SELLER_ROLE,
} from "../constants/constants";
import { NavigateFunction } from "react-router-dom";
import { NavigationRoutes } from "@/components/navigation/constants";

export const handleReturnClick = (
  item: IReturn,
  roles: string,
  navigation: NavigateFunction
) => {
  if (roles.includes(SELLER_ROLE)) {
    if (
      (item.statutId === 3 || item.statutId === 5) &&
      item.isSellerVIP === true &&
      item.perRequest === true
    ) {
      navigation(NavigationRoutes.PJ);
    } else if (
      (item.statutId === 3 || item.statutId === 5) &&
      item.isSellerVIP === true
    ) {
      navigation(NavigationRoutes.RETURN_DELIVERY_VALIDATION);
    } else {
      navigation(NavigationRoutes.RETURN_DETAILS);
    }
  } else if (roles.includes(DELIVERY_ROLE)) {
    if (item.statutId === 2) {
      navigation(NavigationRoutes.RETURN_PICK_UP_VALIDATION);
    }
    if (item.statutId === 3) {
      if (
        item.isSellerVIP === false &&
        (item.entityId === null || item.entityId === MILKRUN_ID)
      ) {
        navigation(NavigationRoutes.RETURN_DETAILS, {
          state: {
            toValidate: true,
          },
        });
      } else {
        navigation(NavigationRoutes.RETURN_DETAILS);
      }
    }
  } else if (roles.includes(HUB_ROLE)) {
    if (item.statutId === 5) {
      if (item.isSellerVIP === false) {
        navigation(NavigationRoutes.PJ); // PJ
      } else {
        navigation(NavigationRoutes.RETURN_DETAILS);
      }
    } else if (item.statutId === 3) {
      navigation(NavigationRoutes.RETURN_DELIVERY_VALIDATION);
    } else {
      navigation(NavigationRoutes.RETURN_DETAILS);
    }
  } else {
    navigation(NavigationRoutes.RETURN_DETAILS);
  }
};
