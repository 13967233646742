import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "@/lib/utils";
import {
  CommandInput,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandItem,
  Command,
} from "../ui/command";
import { ChevronsUpDownIcon, Check, LoaderCircle } from "lucide-react";
import { Button } from "../ui/button";
import { HUB } from "@/interfaces/HUB";
import { getHUBs } from "@/services/hub-list.service";
import { useEffect, useState } from "react";

interface DropOffSelectProps {
  selectedDropOff: HUB;
  setSelectedDropOff: (dropOff: HUB) => void;
}

const DropOffSelect: React.FC<DropOffSelectProps> = ({
  selectedDropOff,
  setSelectedDropOff,
}) => {
  const [dropOffOpen, setDropOffOpen] = useState(false);
  const [dropOffs, setDropOffs] = useState<HUB[]>([]);
  const [loading, setHubLoading] = useState(false);

  const fetchHUBs = async () => {
    setHubLoading(true);
    const response = await getHUBs();
    if (response.status === 200 && response.data.success === true) {
      setDropOffs(response.data.data);
      setHubLoading(false);
    } else {
      setHubLoading(false);
    }
    setHubLoading(false);
  };

  useEffect(() => {
    fetchHUBs();
  }, []);

  return (
    <div className="grid gap-1 ">
      <label className="text-sm font-medium" htmlFor="supplier">
        Point de drop-off
      </label>
      <div className="flex items-center justify-between gap-2">
        <Popover open={dropOffOpen} onOpenChange={setDropOffOpen}>
          <PopoverTrigger asChild>
            <Button
              className="w-full justify-between "
              role="combobox"
              variant="outline"
            >
              {selectedDropOff.id && selectedDropOff
                ? selectedDropOff.name
                : "Choisir un point de drop-off"}
              <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]">
            <Command>
              <CommandInput
                className="h-9"
                placeholder="Chercher un point de drop-off"
              />
              <CommandEmpty>Aucune demande trouvée</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  {dropOffs &&
                    dropOffs.map((dropOff) => (
                      <CommandItem
                        key={dropOff.id}
                        value={dropOff.name}
                        onSelect={() => {
                          setSelectedDropOff(dropOff);
                          setDropOffOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            dropOff.id === selectedDropOff.id
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {dropOff.name}
                      </CommandItem>
                    ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {loading && (
          <LoaderCircle className="animate-spin" color="grey" size={20} />
        )}
      </div>
    </div>
  );
};

export default DropOffSelect;
