import DeleteConfirmationDialog from "@/components/dialogs/DeleteConfirmationDialog";
import ReturnCreationDialog from "@/components/dialogs/ReturnCreationDialog";
import CanceledReturn from "@/components/return/CanceledReturn";
import Return from "@/components/return/Return";
import ReturnSkeleton from "@/components/return/ReturnSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Toaster } from "@/components/ui/toaster";
import { WAREHOUSE_ROLE } from "@/constants/constants";
import { IReturn } from "@/interfaces/Return";
import { getReturnsCanceledBySeller } from "@/services/return-list.service";
import { resetReturnHeader } from "@/store/reducers/returnHeaderReducer";
import { resetArticles } from "@/store/reducers/returnProductsReducer";
import { resetReturn } from "@/store/reducers/returnReducer";
import { RootState } from "@/store/store";
import { fetchReturnList } from "@/utils/return-list-fetching";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

interface DashboardProps {
  // Define any props for the Dashboard component here
}

const Dashboard: React.FC<DashboardProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [returnList, setReturnList] = useState<IReturn[]>([] as IReturn[]);
  const [canceledReturnList, setCanceledReturnList] = useState<IReturn[]>(
    [] as IReturn[]
  );
  const [isLoading, setIsLoading] = useState(true);
  const [currentListValue, setCurrentListValue] = useState<string>("normal");
  const [canceledListLoading, setCanceledListLoading] = useState(true);
  const { roles } = useSelector((state: RootState) => state.authentication);
  const isRefreshing = useSelector(
    (state: RootState) => state.refresh.isRefreshing
  );
  const fetchReturnsCancelled = async () => {
    try {
      setCanceledListLoading(true);
      const response = await getReturnsCanceledBySeller();
      setCanceledReturnList(response);
    } catch (error) {
      console.error(error);
    } finally {
      setCanceledListLoading(false);
    }
  };
  const resetPersistStates = () => {
    dispatch(resetReturnHeader());
    dispatch(resetArticles());
    dispatch(resetReturn());
  };

  useEffect(() => {
    fetchReturnList(setIsLoading, setReturnList, roles);
  }, [isRefreshing]);

  useEffect(() => {
    resetPersistStates();
  }, []);

  useEffect(() => {
    if (currentListValue === "cancelled") fetchReturnsCancelled();
  }, [currentListValue]);

  useEffect(() => {
    if (
      location.state &&
      location.state.tab === "cancelled" &&
      location.state.tab !== null
    ) {
      setCurrentListValue("cancelled");
      navigate(".", { state: null, replace: true });
    } else {
      setCurrentListValue("normal");
    }
  }, []);

  return (
    <>
      <ScrollArea className="flex-grow px-2">
        <Tabs value={currentListValue} className="w-full px-2">
          {roles.includes(WAREHOUSE_ROLE) && (
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger
                value="normal"
                onClick={() => {
                  setCurrentListValue("normal");
                }}
              >
                Tournées vendeurs
              </TabsTrigger>
              <TabsTrigger
                value="cancelled"
                onClick={() => {
                  setCurrentListValue("cancelled");
                }}
              >
                Tournées annulées vendeur
              </TabsTrigger>
            </TabsList>
          )}
          <TabsContent value="normal">
            {!isLoading ? (
              <div className="p-2">
                {returnList && returnList.length !== 0 ? (
                  returnList.map((item) => (
                    <Return key={item.numTournee} return_={item} />
                  ))
                ) : (
                  <div className="text-center text-gray-500 font-sans">
                    Aucune tournée disponible
                  </div>
                )}
              </div>
            ) : (
              <div className="p-2">
                {Array.from({ length: 5 }).map((_, index) => (
                  <ReturnSkeleton key={index} />
                ))}
              </div>
            )}
          </TabsContent>
          <TabsContent value="cancelled">
            {!canceledListLoading ? (
              <div className="p-2">
                {canceledReturnList && canceledReturnList.length !== 0 ? (
                  canceledReturnList.map((item) => (
                    <CanceledReturn key={item.numTournee} return_={item} />
                  ))
                ) : (
                  <div className="text-center text-gray-500 font-sans">
                    Aucune tournée annulée
                  </div>
                )}
              </div>
            ) : (
              <div className="p-2">
                {Array.from({ length: 5 }).map((_, index) => (
                  <ReturnSkeleton key={index} />
                ))}
              </div>
            )}
          </TabsContent>
        </Tabs>
      </ScrollArea>
      <ReturnCreationDialog />
      <DeleteConfirmationDialog />
      <Toaster />
    </>
  );
};

export default Dashboard;
