import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "@/lib/utils";
import {
  CommandInput,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandItem,
  Command,
} from "../ui/command";
import { ChevronsUpDownIcon, Check, LoaderCircle } from "lucide-react";
import { Button } from "../ui/button";
import { Motif } from "@/interfaces/Motif";

interface MotifSelectProps {
  motifs?: Motif[];
  selectedMotif: Motif;
  setSelectedMotif: (supplier: Motif) => void;
  loading?: boolean;
  disabled?: boolean;
  noLabel?: boolean;
}

const MotifSelect: React.FC<MotifSelectProps> = ({
  motifs,
  selectedMotif,
  setSelectedMotif,
  loading,
  disabled,
  noLabel,
}) => {
  const [motifOpen, setMotifOpen] = React.useState(false);

  return (
    <div className="grid gap-1 ">
      {!noLabel && (
        <label className="text-sm font-medium" htmlFor="supplier">
          Motif
        </label>
      )}
      <div className="flex items-center justify-between gap-2">
        <Popover open={motifOpen} onOpenChange={setMotifOpen}>
          <PopoverTrigger asChild>
            <Button
              disabled={disabled}
              className="w-full justify-between h-10"
              role="combobox"
              variant="outline"
            >
              {selectedMotif.name && selectedMotif
                ? selectedMotif.name
                : "Choisir un motif"}
              <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]">
            <Command>
              <CommandInput className="h-9" placeholder="Chercher un motif" />
              <CommandEmpty>Aucun motif trouvé</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  {motifs &&
                    motifs.map((motif) => (
                      <CommandItem
                        key={motif.id}
                        value={motif.id}
                        onSelect={() => {
                          setSelectedMotif(motif);
                          setMotifOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            selectedMotif.id === motif.id
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {motif.name}
                      </CommandItem>
                    ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {loading && (
          <LoaderCircle className="animate-spin" color="grey" size={20} />
        )}
      </div>
    </div>
  );
};

export default MotifSelect;
