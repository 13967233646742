import { MapPin } from "lucide-react";
import React from "react";

interface DropOffTagProps {
  entityId: number | null;
  entityName: string | null;
}

const DropOffTag: React.FC<DropOffTagProps> = ({ entityId, entityName }) => {
  return (
    <>
      {entityId && (
        <div
          className="flex items-center rounded-md px-2 py-1 text-xs"
          style={{ backgroundColor: "#ebebff" }}
        >
          <MapPin className="h-3 w-3 mr-1 text-[#5555fd]" />
          <span className="text-[#5555fd]">{entityName}</span>
        </div>
      )}
    </>
  );
};

export default DropOffTag;
