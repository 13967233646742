export enum NavigationRoutes {
  DASHBOARD = "/dashboard",
  RETURN_DETAILS = "/retour/details",
  RETURN_CREATE = "/retour/create",
  RETURN_PICK_UP_VALIDATION = "/retour/validation-ramassage",
  RETURN_DELIVERY_VALIDATION = "/retour/validation-livraison",
  ROOT = "/",
  NOT_FOUND = "*",
  NOT_AUTHORIZED = "/not-authorized",
  RETURN_LITIGE = "/retour/litige",
  PJ = "/retour/pj",
  REQUEST_LITIGE = "/demande/litige",
}
