import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Button } from "../ui/button";
import DialogLoaderButton from "../loader-button/DialogLoaderButton";
import { toast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { NavigationRoutes } from "../navigation/constants";
import { annulerTournee } from "@/services/return-cancel.service";

interface CancelReturnDialogProps {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
  returnNumber: number;
}

const CancelReturnDialog: React.FC<CancelReturnDialogProps> = ({
  isOpened,
  setIsOpened,
  returnNumber,
}) => {
  const navigate = useNavigate();
  const [PO, setPO] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleValidate_ = async () => {
    try {
      setIsLoading(true);
      const response = await annulerTournee(returnNumber);
      if (response.status === 200 && response.data.success === true) {
        toast({
          title: "Réception confirmée",
          variant: "success",
        });
        setPO(response.data.data);
      }
    } catch (error) {
      console.error(error);
      toast({
        title: "Erreur lors de la confirmation",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (PO) {
      setIsOpened(false);
      setPO("");
      navigate(NavigationRoutes.DASHBOARD);
    } else {
      setIsOpened(false);
    }
  };

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent>
        {!PO ? (
          <>
            <DialogHeader>
              <DialogTitle>Confirmation</DialogTitle>
              <DialogDescription>
                Étes-vous sûr de vouloir refuser la réception ?
              </DialogDescription>
            </DialogHeader>
            <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:gap-0">
              <Button
                variant="outline"
                className="mr-2"
                onClick={handleClose}
                disabled={isLoading}
              >
                Annuler
              </Button>
              <DialogLoaderButton
                name="Confirmer"
                handleSubmit={handleValidate_}
                isLoading={isLoading}
                variant="destructive"
              />
            </DialogFooter>
          </>
        ) : (
          <DialogHeader>
            <DialogTitle>Réception confirmée</DialogTitle>
            <DialogDescription>
              Le numéro de commande est : {PO}
            </DialogDescription>
          </DialogHeader>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CancelReturnDialog;
