import React, { useEffect, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "@/lib/utils";
import {
  CommandInput,
  CommandEmpty,
  CommandList,
  CommandGroup,
  CommandItem,
  Command,
} from "../ui/command";
import { ChevronsUpDownIcon, Check, LoaderCircle } from "lucide-react";
import { Button } from "../ui/button";
import { Seller } from "@/interfaces/Seller";
import { Switch } from "../ui/switch";
import {
  getVendeursWithRequests_NonVendable,
  getVendeursWithRequests_Vendable,
} from "@/services/vendor-list-with-request.service";
import { getVendeurs } from "@/services/vendor-list.service";

interface SellerSelectProps {
  isModeVendable: boolean;
  isRequestMode: boolean;
  selectedSeller: Seller;
  setSelectedSeller: (supplier: Seller) => void;
}

const SellerSelect: React.FC<SellerSelectProps> = ({
  selectedSeller,
  setSelectedSeller,
  isRequestMode,
  isModeVendable,
}) => {
  const [supplierOpen, setSupplierOpen] = useState(false);
  const [sellersLoading, setSellersLoading] = useState(false);
  const [sellers, setSellers] = useState<Seller[]>([]);

  const fetchSellersWithRequestsNonVendable = async () => {
    setSellersLoading(true);
    const response = await getVendeursWithRequests_NonVendable();
    if (response.status === 200 && response.data.success === true) {
      setSellers(response.data.data);
      setSellersLoading(false);
    } else {
      setSellersLoading(false);
    }
    setSellersLoading(false);
  };
  const fetchSellersWithRequestsVendable = async () => {
    setSellersLoading(true);
    const response = await getVendeursWithRequests_Vendable();
    if (response.status === 200 && response.data.success === true) {
      setSellers(response.data.data);
      setSellersLoading(false);
    } else {
      setSellersLoading(false);
    }
    setSellersLoading(false);
  };

  const fetchSellers = async () => {
    setSellersLoading(true);
    const response = await getVendeurs();
    if (response.status === 200 && response.data.success === true) {
      setSellers(response.data.data);
      setSellersLoading(false);
    } else {
      setSellersLoading(false);
    }
    setSellersLoading(false);
  };

  useEffect(() => {
    if (isRequestMode === true) {
      setSellers([]);
      if (isModeVendable) {
        fetchSellersWithRequestsVendable();
      } else {
        fetchSellersWithRequestsNonVendable();
      }
    } else {
      fetchSellers();
    }
  }, [isRequestMode, isModeVendable]);

  return (
    <div className="grid gap-1 ">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center justify-between gap-2">
          <label className="text-sm font-medium" htmlFor="supplier">
            Fournisseur
          </label>
        </div>
        <div className="flex items-center w-8 justify-between gap-2">
          <label className="text-sm font-medium" htmlFor="supplier">
            VIP
          </label>
        </div>
      </div>
      <div className="flex items-center justify-between gap-2">
        <Popover open={supplierOpen} onOpenChange={setSupplierOpen}>
          <PopoverTrigger asChild>
            <div className="flex items-center gap-2 w-full">
              <Button
                className="w-full justify-between "
                role="combobox"
                variant="outline"
              >
                {selectedSeller.sellerName && selectedSeller
                  ? selectedSeller.sellerName
                  : "Choisir un fournisseur"}
                <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
              <div className="flex gap-2 flex-col">
                <Switch checked={selectedSeller.isSellerVIP} disabled />
              </div>
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-[--radix-popover-trigger-width] max-h-[--radix-popover-content-available-height]">
            <Command>
              <CommandInput
                className="h-9"
                placeholder="Chercher un fournisseur"
              />
              <CommandEmpty>Aucun fournisseur trouvé</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  {sellers &&
                    sellers.map((seller) => (
                      <CommandItem
                        key={seller.sellerId}
                        value={seller.sellerName}
                        onSelect={() => {
                          setSelectedSeller(seller);
                          setSupplierOpen(false);
                        }}
                      >
                        <Check
                          className={cn(
                            "mr-2 h-4 w-4",
                            selectedSeller.sellerId === seller.sellerId
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {seller.sellerName}
                      </CommandItem>
                    ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
        {sellersLoading && (
          <LoaderCircle className="animate-spin" color="grey" size={20} />
        )}
      </div>
    </div>
  );
};

export default SellerSelect;
