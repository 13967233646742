import React from "react";
import { Button } from "../ui/button";
import { LoaderCircle } from "lucide-react";

interface ActionButtonProps {
  label: string;
  handleClick: () => void;
  disabled: boolean;
  loading: boolean;
  color?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  handleClick,
  loading,
  disabled,
  label,
  color,
}) => {
  return (
    <Button
      onClick={handleClick}
      disabled={loading || disabled}
      className={`w-full min-h-10 text-white ${
        color || "bg-[#23504F] hover:bg-[#2C5F5C]"
      }`}
    >
      {loading ? (
        <LoaderCircle className="animate-spin" />
      ) : (
        <p className="text-md font-medium">{label}</p>
      )}
    </Button>
  );
};

export default ActionButton;
